export const contents = [
  {
    title: 'privacy.content1.title',
    content: ['privacy.content1.paragraph1']
  },
  {
    title: 'privacy.content2.title',
    content: [
      'privacy.content2.paragraph1',
      'privacy.content2.paragraph2',
      'privacy.content2.paragraph3',
      'privacy.content2.paragraph4'
    ]
  },
  {
    title: 'privacy.content3.title',
    content: ['privacy.content3.paragraph1']
  },
  {
    title: 'privacy.content4.title',
    content: ['privacy.content4.paragraph1', 'privacy.content4.paragraph2', 'privacy.content4.paragraph3']
  },
  {
    title: 'privacy.content5.title',
    content: ['privacy.content5.paragraph1']
  },
  {
    title: 'privacy.content6.title',
    content: ['privacy.content6.paragraph1', 'privacy.content6.paragraph2']
  },
  {
    title: 'privacy.content7.title',
    content: ['privacy.content7.paragraph1']
  },
  {
    title: 'privacy.content8.title',
    content: ['privacy.content8.paragraph1']
  },
  {
    title: 'privacy.content9.title',
    content: ['privacy.content9.paragraph1', 'privacy.content9.paragraph2']
  },
  {
    title: 'privacy.content10.title',
    content: ['privacy.content10.paragraph1', 'privacy.content10.paragraph2'],
    list: [
      {
        listTitle: 'privacy.content10.list1.title',
        listSubTitle: 'privacy.content10.list1.subTitle',
        features: [
          'privacy.content10.list1.feature1',
          'privacy.content10.list1.feature2',
          'privacy.content10.list1.feature3'
        ]
      },
      {
        listTitle: 'privacy.content10.list2.title',
        listSubTitle: 'privacy.content10.list2.subTitle',
        features: [
          'privacy.content10.list2.feature1',
          'privacy.content10.list2.feature2',
          'privacy.content10.list2.feature3'
        ]
      }
    ]
  }
]
