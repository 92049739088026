import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { contents } from '/static/data/privacy'
import Layout from 'layouts/index'
import Seo from 'components/seo'

const PrivacyPage = () => {
  const { t } = useTranslation()

  const Para = ({ children }) => {
    return <p className="mb-7 text-gray-600">{children}</p>
  }
  Para.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
  }
  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: t('privacy.meta.title'),
            // metaDescription: t('enterpriseSupportPage.meta.description'),
            metaUrl: 'https://www.mlytics.com/privacy/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section className=" container pt-[5rem] pb-[6em]" style={{ fontFamily: "'Noto Sans',sans-serif" }}>
          <div className="flex-1 text-center flex items-center flex-col px-[10%] md:px-[15%] pb-[120px]">
            <p className="pb-5 text-lg text-blue-200">{t('privacy.title')}</p>
            <img
              className="h-12"
              src="https://www.mlytics.com/wp-content/uploads/2022/07/logo_Mlytics_word.svg"
              alt={t('privacy.subTitle')}
            />
            <h1 className="leading-snug text-primary-500 text-4xl lg:text-[3em] w-full">{t('privacy.subTitle')}</h1>
          </div>
          <div>
            <Para>{t('privacy.description1')}</Para>
            <Para>{t('privacy.description2')}</Para>
            <Para>{t('privacy.description3')}</Para>
          </div>
          {contents.map(({ content, title, list }, key) => (
            <div key={`cotent_${key}`} className="text-sm md:text-base leading-7 md:leading-8">
              <h3 className="mb-5 text-gray-700 md:text-sm font-bold">{t(title)}</h3>

              {content.map((paragraph, keyContent) => (
                <Para key={`${keyContent}_paragraph`}>{t(paragraph)}</Para>
              ))}

              {list ? (
                <ol className="text-gray-600 ml-[4rem] ">
                  {list.map(({ features, listTitle, listSubTitle }, keyL) => (
                    <li key={`lists_${keyL}`} className="relative mb-10">
                      <span className="mr-1 absolute left-[-20px]">{keyL + 1}.</span>
                      <span className="font-bold">{t(listTitle)}</span>
                      <p className=" mb-7">{t(listSubTitle)}</p>
                      <ol className="ml-5">
                        {features.map((feature, keyF) => (
                          <li key={`features_${keyF}`} className="relative mb-2">
                            <span className=" absolute left-[-20px] mr-1">{keyF + 1}.</span>
                            <span>{t(feature)}</span>
                          </li>
                        ))}
                      </ol>
                    </li>
                  ))}
                </ol>
              ) : null}
            </div>
          ))}
        </section>

        {/* <GotoDemoHero /> */}
      </React.Fragment>
    </Layout>
  )
}

export default PrivacyPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
